/*
 * @Author: w
 * @Date: 2021-05-27 15:33:16
 * @LastEditors: w
 * @LastEditTime: 2021-10-19 11:21:32
 */
// 费用管理模块

export default [
  
  // 财务对账 begin
  {
    name: '价格试算',
    path: 'cost/oPrice/priceTrial',
    component: () => import('@/views/cost/oPrice/priceTrial'),
    meta: {requireAuth: true},
  },
  {
    name: '我的费用',
    path: 'cost/oPrice/myCost',
    component: () => import('@/views/cost/oPrice/myCost'),
    meta: {requireAuth: true},
  },
  {
    name: '我的每日账单',
    path: 'cost/oPrice/myBill',
    component: () => import('@/views/cost/oPrice/myBill'),
    meta: {requireAuth: true},
  },
  {
    name: '我的退款',
    path: 'cost/oPrice/myRefunds',
    component: () => import('@/views/cost/oPrice/myRefunds'),
    meta: {requireAuth: true},
  },
  {
    name: '充值管理',
    path: 'cost/oPrice/addCostManage',
    component: () => import('@/views/cost/oPrice/addCostManage'),
    meta: {requireAuth: true},
  },
  {
    name: '财务流水账',
    path: 'cost/oPrice/financialFlows',
    component: () => import('@/views/cost/oPrice/financialFlows'),
    meta: {requireAuth: true},
  },
  // 财务对账 end

  // 财务报表 begin
  {
    name: '包裹费用',
    path: 'cost/financialReport/packageCost',
    component: () => import('@/views/cost/financialReport/packageCost'),
    meta: {requireAuth: true},
  },
  // 财务报表 end

  // 账户 begin
  {
    name: '我的账户',
    path: 'cost/account/queryAccount',
    component: () => import('@/views/cost/account/queryAccount'),
    meta: {requireAuth: true},
  },
  {
    name: '我的流水账',
    path: 'cost/account/querytradeflow',
    component: () => import('@/views/cost/account/querytradeflow'),
    meta: {requireAuth: true},
  },
  // 账户 end
]
