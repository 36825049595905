/*
 * @Author: w
 * @Date: 2019-08-10 16:55:14
 * @LastEditors: w
 * @LastEditTime : 2020-02-10 13:47:37
 */
// 国内处理中心操作

export default [

  // 包裹管理 begin
  {
    name: "新增包裹",
    path: 'packageManage/oPackageManage/addPackage',
    component: () => import('@/views/packageManage/oPackageManage/addPackage'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "导入包裹",
    path: 'packageManage/oPackageManage/importPackage',
    component: () => import('@/views/packageManage/oPackageManage/importPackage'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "空海运大货",
    path: 'packageManage/oPackageManage/largeCargo',
    component: () => import('@/views/packageManage/oPackageManage/largeCargo'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "全部包裹",
    path: 'packageManage/oPackageManage/allPackage',
    component: () => import('@/views/packageManage/oPackageManage/allPackage'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "异常包裹",
    path: 'packageManage/oPackageManage/exceptionPackage',
    component: () => import('@/views/packageManage/oPackageManage/exceptionPackage'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹回传",
    path: 'packageManage/oPackageManage/packageBack',
    component: () => import('@/views/packageManage/oPackageManage/packageBack'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "跟踪号有更新",
    path: 'packageManage/oPackageManage/trackingNumberUpdate',
    component: () => import('@/views/packageManage/oPackageManage/trackingNumberUpdate'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹查询",
    path: 'packageManage/oPackageManage/packageSearch',
    component: () => import('@/views/packageManage/oPackageManage/packageSearch'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "货箱查询",
    path: 'packageManage/oPackageManage/boxesSearch',
    component: () => import('@/views/packageManage/oPackageManage/boxesSearch'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "交货清单查询",
    path: 'packageManage/oPackageManage/deliverySearch',
    component: () => import('@/views/packageManage/oPackageManage/deliverySearch'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹发货查询",
    path: 'packageManage/oPackageManage/packageDeliverSearch',
    component: () => import('@/views/packageManage/oPackageManage/packageDeliverSearch'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "第三方代发货管理",
    path: 'packageManage/oPackageManage/thirdDeliveryManage',
    component: () => import('@/views/packageManage/oPackageManage/thirdDeliveryManage'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹待作废管理",
    path: 'packageManage/oPackageManage/packageInvalidAdmin',
    component: () => import('@/views/packageManage/oPackageManage/packageInvalidAdmin'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹扣费失败",
    path: 'packageManage/oPackageManage/packageDeductionFailed',
    component: () => import('@/views/packageManage/oPackageManage/packageDeductionFailed'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "导出查看",
    path: 'packageManage/oPackageManage/excelPortTask',
    component: () => import('@/views/packageManage/oPackageManage/exportList'),
    meta: { requireAuth: true }
  },
  // 包裹管理 end

  // 包裹操作 begin
  {
    name: "包裹校验",
    path: 'packageManage/oPackageOperation/packageCheck',
    component: () => import('@/views/packageManage/oPackageOperation/packageCheck'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹称重扫描",
    path: 'packageManage/oPackageOperation/packageWeightScan',
    component: () => import('@/views/packageManage/oPackageOperation/packageWeightScan'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹下单监测",
    path: 'packageManage/oPackageOperation/packageOrder',
    component: () => import('@/views/packageManage/oPackageOperation/packageOrder'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "包裹补打面单扫描",
    path: 'packageManage/oPackageOperation/packageRemedialScan',
    component: () => import('@/views/packageManage/oPackageOperation/packageRemedialScan'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "装袋扫描",
    path: 'packageManage/oPackageOperation/packScan',
    component: () => import('@/views/packageManage/oPackageOperation/packScan'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "箱货称重扫描",
    path: 'packageManage/oPackageOperation/boxesWeightScan',
    component: () => import('@/views/packageManage/oPackageOperation/boxesWeightScan'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "交货清单",
    path: 'packageManage/oPackageOperation/shipmentBill',
    component: () => import('@/views/packageManage/oPackageOperation/shipmentBill'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "物流商交货",
    path: 'packageManage/oPackageOperation/billDelivery',
    component: () => import('@/views/packageManage/oPackageOperation/billDelivery'),
    meta: {
      requireAuth: true
    }
  },

  // 包裹操作 end

  // 自发头程 begin
  {
    name: "装箱扫描",
    path: 'packageManage/auto/packBoxScan',
    component: () => import('@/views/packageManage/auto/packBoxScan'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "自装箱代发",
    path: 'packageManage/auto/selfBoxing',
    component: () => import('@/views/packageManage/auto/selfBoxing'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "自发头程",
    path: 'packageManage/auto/autoHead',
    component: () => import('@/views/packageManage/auto/autoHead'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: "头程发货",
    path: 'packageManage/auto/headShipment',
    component: () => import('@/views/packageManage/auto/headShipment'),
    meta: {
      requireAuth: true
    }
  },
  // 自发头程 end

  // 包裹退货 start
  {
    name: '退货管理',
    path: 'packageManage/packageReturn/returnAdmin',
    component: () => import('@/views/packageManage/packageReturn/returnAdmin'),
    meta: {
      requireAuth: true
    }
  },
  // 包裹退货 end
]
