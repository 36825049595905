import axios from "axios";
import router from '@/router';
import { Notification, MessageBox, Message } from 'element-ui';
import { getToken, clearToken } from './user';
import store from '@/store';

// 基础api
let baseurl = process.env.VUE_APP_ROOT;

// 通用api
let COMMONURL = process.env.VUE_APP_COMMON;

// 导出api
let EXCELURL = process.env.VUE_APP_EXCEL;

const instance = axios.create({
    headers: {},
    timeout: 60000,
    baseURL: baseurl
});

//axios的一些配置，比如发送请求显示loading，请求回来loading消失之类的
instance.interceptors.request.use((config) => { //配置发送请求的信息
    config.headers = config.headers || {};
    config.headers.Authorization = getToken();
    let curr_url = window.location.protocol + '//' + window.location.host;
    config.headers.OriginUrl = curr_url;
    return config;
});

// 添加响应拦截器
instance.interceptors.response.use(response => {

    let responseData = response.data;

    // 如果是blob, 说明是下载文件流，此时不需要判断res.code
    if (response.config.responseType === 'blob') {
        // 判断文件流返回数据是否报错
        if (!responseData.type.includes('application/json')) {
            let dis = response.headers['content-disposition'];
            if (!dis || !dis.split(';')[1].split('=')[1]) {
                return Promise.reject(new Error('导出失败!'));
            }
            let filename = decodeURIComponent(dis.split(';')[1].split('=')[1])
            return { responseData, filename };
        }
    }

    let code = responseData.code;

    switch (code) {

        case 200:
            return responseData;
            break;

        case 301:

            // token失效，页面f5刷新，直接跳登录页
            if (!store.state.currentNavs.length) {
                sessionStorage.setItem('return_url', window.location.pathname);
                clearToken();
                window.location.href = '/login';
                return;
            }

            MessageBox.alert('抱歉，登陆已超时！您可以点击重新登录。', '系统提示', {
                type: 'error',
                showClose: false,
                callback: action => {
                    sessionStorage.setItem('return_url', window.location.pathname);
                    clearToken();
                    window.location.href = '/login';
                }
            });
            return Promise.reject(responseData);
            break;

        default:
            Message({
                message: responseData.msg ? responseData.msg : '系统未知错误,请反馈给管理员',
                duration: '3000',
                showClose: true,
                type: 'error'
            })
            return responseData;
            break;

    }
}, e => {
    return Promise.reject(e);
});
export default instance
export { baseurl, COMMONURL, EXCELURL }