import common from '@/assets/scripts/common';
import { BroadcastChannel } from 'broadcast-channel';

export class busCtrl {
  constructor() {
    this.subscribe = {};
    this.broadcast = new BroadcastChannel(`cpms-broadcast-channel`);
    this.broadcast.addEventListener('message', (message) => {
      this.listenerMessage(this.subscribe, message);
    });
  }
  listenerMessage(observer, message) {
    const messageKey = Object.keys(message);
    messageKey.forEach(k => {
      let list = observer[k] || [];
      list.forEach(lk => lk(message[k]));
    })
  }
  /**
   * 订阅全局消息广播(支持同源跨浏览器标签，部分浏览器不支持跨标签)
   * @param key 事件 key 值
   * @param callBack 回调函数
   */
  on(key, callBack) {
    if (common.isEmpty(key) || common.isEmpty(callBack)) { return; }
    if (common.isEmpty(this.subscribe[key])) {
      this.subscribe[key] = [callBack];
    } else {
      this.subscribe[key].push(callBack);
    }
  }
  /**
   * 触发全局消息广播(支持同源跨浏览器标签，部分浏览器不支持跨标签)
   * @param key 事件 key 值
   * @param value 调用订阅事件的参数
   */
  emit(key, value) {
    if (!common.isBoolean(this.broadcast)) {
      this.broadcast.postMessage({ [key]: value }); // 广播到当前源下的所有窗口
    } else {
      console.warn('当前浏览器不支持跨跨域标签通讯');
    }
    if (!common.isEmpty(this.subscribe[key])) {
      for (let i = 0, len = this.subscribe[key].length; i < len; i++) {
        this.subscribe[key][i](value);
      }
    }
  }
}

const bus = new busCtrl();
export default bus;