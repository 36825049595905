// 系统设置模块
export default [
    {
        name: "系统设置首页",
        path: 'system/index',
        component: () => import('@/views/system/index'),
        meta: { requireAuth: true }
    },

    // 权限设置 begin
    {
        name: "用户信息",
        path: 'system/authority/userMessage',
        component: () => import('@/views/system/authority/userMessage'),
        meta: { requireAuth: true }
    },
    {
        name: "修改密码",
        path: 'system/authority/modifyPassword',
        component: () => import('@/views/system/authority/modifyPassword'),
        meta: { requireAuth: true }
    },
    {
        name: "部门管理",
        path: 'system/authority/departmentManage',
        component: () => import('@/views/system/authority/departmentManage'),
        meta: { requireAuth: true }
    },
    {
        name: "岗位权限管理",
        path: 'system/authority/postManage',
        component: () => import('@/views/system/authority/postManage'),
        meta: { requireAuth: true }
    },
    {
        name: "员工管理",
        path: 'system/authority/staffManage',
        component: () => import('@/views/system/authority/staffManage'),
        meta: { requireAuth: true }
    },
    {
        name: "开发者",
        path: 'system/authority/developer',
        component: () => import('@/views/system/authority/developer'),
        meta: { requireAuth: true }
    },
    // 权限设置 end

    // 物流设置 end
    {
        name: "授权的邮寄方式",
        path: 'system/logisticsSetting/authNetAndEmail',
        component: () => import('@/views/system/logisticsSetting/authNetAndEmail'),
        meta: { requireAuth: true }
    },
    {
        name: "货箱资料维护",
        path: 'system/logisticsSetting/BoxesData',
        component: () => import('@/views/system/logisticsSetting/BoxesData'),
        meta: { requireAuth: true }
    },
    {
        name: "操作设置",
        path: 'system/logisticsSetting/operationSettings',
        component: () => import('@/views/system/logisticsSetting/operationSettings'),
        meta: { requireAuth: true }
    },
    {
        name: "打印机设置",
        path: 'system/logisticsSetting/printSettings',
        component: () => import('@/views/system/logisticsSetting/printSettings'),
        meta: { requireAuth: true }
    },
    {
        name: "常用异常原因维护",
        path: 'system/logisticsSetting/abnormalCauses',
        component: () => import('@/views/system/logisticsSetting/abnormalCauses'),
        meta: { requireAuth: true }
    },
    // 物流设置 end

]
