/*
 * @Author: w
 * @Date: 2022-02-16 17:29:39
 * @LastEditors: w
 * @LastEditTime: 2022-02-18 10:26:48
 */
// 系统设置模块
export default [

  // 运营时效管理 begin
  {
    name: "订单时效监控",
    path:'merchants/batchTimelinessAdmin/orderTimelinessMonitoring',
    component: () => import('@/views/merchants/batchTimelinessAdmin/orderTimelinessMonitoring'),
    meta: {requireAuth: true}
  },
  {
    name: "时效统计",
    path:'merchants/batchTimelinessAdmin/orderTimelinessStatistics',
    component: () => import('@/views/merchants/batchTimelinessAdmin/orderTimelinessStatistics'),
    meta: {requireAuth: true}
  },
  // 运营时效管理 end

]
