/**
 * @alias Date.format 日期格式化
 * @description 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @example 例子：(new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
Date.prototype.format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

/**
 * @description 判断是否为空对象
 * @param {o} 对象
 * 页面使用示例：
 * import isEmptyObject from '@/assets/scripts/utils';
 * isEmptyObject(object)
 */
export function isEmptyObject(o) {
  for (let i in o) {
    return false;
  }
  return true;
}

/*
*  判断数据类型
*/
export function getDataType(data) {
  return Object.prototype.toString.apply(data)
    .match(/^\[object (\w+)\]$/i)[1].toLowerCase();
}

//下载文件资源
export function downFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
    setTimeout(_ => {
      document.body.removeChild(link);
    }, 50);
  }
}

/*  下载远程资源 */
export function downLinkFile(link, filename) {
  var ajax = new XMLHttpRequest();
  ajax.open('get', link, true);
  ajax.responseType = 'blob';
  ajax.onload = function (e) {
    downFile(e.target.response, filename);
  };
  setTimeout(function () {
    ajax.send();
  }, 0);
}

//下载文件链接
export function downLink(url) {
  if (!url) return;
  // 对url的特殊符号+进行处理
  let codeList = ['+'];
  codeList.forEach(k => {
    url = url.replace(new RegExp('\\' + k, 'g'), encodeURIComponent(k));
  })

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = url;
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 50);
}


/**
 * @param s:需要保留的小数点位
 * @description js重写toFixed方法（兼容负数），js四舍五入保留小数不准确
 * @returns {string} 返回相应小数点位的数字字符串
 */
Number.prototype.myToFixed = function (s) {
  let v = parseFloat(this);
  let pow = Math.pow(10, s);
  let result = (Math.round(v * pow) / pow).toString();
  return result;
};

/**
 * @alias getFormDataItem 获取对应formData中的item值
 * @param formData {array} 当前表单formData
 * @param key {string} 需要获取的item项的键值
 * @return item {object} 返回当前item值
 */
export function getFormDataItem(formData, key) {
  for (let item of formData) {
    if ((item.model && item.model === key) || (item.signKey && item.signKey === key)) {
      return item;
    }
  }
}

export function getIdArray(Arr, idName) {
  var array = Arr.map((obj) => {
    return obj[idName] * 1
  })
  return array
}

// list回显   {{renderList('verifyStatus','status',row)}}
export function renderList(list, key, row) {
  var obj = this[list].find(v => v.value == row[key]);
  if (!isEmptyValue(obj)) {
    return obj.label
  }
  return row[key]
};

// 带单位的渲染   {{renderData(row.freight,row.freightCurrency,'--')}}
export function renderData(str, unit, symbol = '') {
  if (isEmptyValue(str)) {
    return symbol
  }
  if (isEmptyValue(unit)) {
    return `${str}`
  }
  return `${str} ${unit}`
};

// 当天
export function getToday(formatType = "yyyy-MM-dd") {
  var str = new Date();
  return (new Date(str)).format(formatType)
}

// 判断是否空值
export function isEmptyValue(v) {
  return v == null || v === '';
}

// 拷贝copy
export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};