import Vue from 'vue';
const isNumber = val => typeof val === 'number' && val === val;
// 点击input时，使input内文本选中，方便修改
Vue.directive('field-select', {
  bind(el) {
    let target = el.children[0].tagName.toUpperCase() === 'INPUT' ?  el.children[0] : el.children[0].children[0];
    el.addEventListener('click', () => {
      target.select();
    })
  },
});

// input框初始化聚焦
Vue.directive('init-focus', {
  inserted(el) {
    el.children[0].select();
  },
});

// directive: 限制input[number]框最大值
Vue.directive('limitMaxNum', {
  inserted(el, binding, vnode) {
    if(binding.value === null){
      return;
    }

    let targetNode = el.firstElementChild;
    targetNode.addEventListener('blur', (e) => {
      // 非数字的输入值直接忽视
      if (!isNumber(parseFloat(targetNode.value))) {
        targetNode.value = '';
        vnode.data.model.callback('');
      }
      if (targetNode.value !== '' && targetNode.value > binding.value) {
        // 直接修改targetNode的值并不会触发input事件，故使用dispatchEvent触发；
        let _event = new Event('blur', { bubbles: true });
        targetNode.value = binding.value;
        vnode.data.model.callback(binding.value);
        targetNode.dispatchEvent(_event);
      }
    });
  },
});

// directive: 限制input[number]框最小值
Vue.directive('limitMinNum', {
  inserted(el, binding, vnode) {
    if(binding.value === null){
      return;
    }

    let targetNode = el.firstElementChild;
    targetNode.addEventListener('blur', () => {
      if (!isNumber(parseFloat(targetNode.value))) {
        targetNode.value = '';
        vnode.data.model.callback('');
      }
      if (targetNode.value !== '' && targetNode.value < binding.value) {
        let _event = new Event('blur', { bubbles: true });
        targetNode.value = binding.value;
        vnode.data.model.callback(binding.value);
        targetNode.dispatchEvent(_event);
      }
    });
  },
});
